const tipsList = {
	tipsList: document.querySelector('#internal_content.tips'),
	tips: document.querySelectorAll('#internal_content.tips .tips_container'),
	tipClick: function(e) {
		let clickedHeading = e.target;
		while(clickedHeading.nodeName !=='H3' || clickedHeading === null) {
			clickedHeading = clickedHeading.parentElement;
		}

		if(clickedHeading) {
			let theTip = clickedHeading;
			while(theTip.nodeName !=='LI' || theTip === null) {
				theTip = theTip.parentElement;
			}
			
			if (theTip.hasAttribute('open')) {
				theTip.removeAttribute('open');
			} else {
				theTip.setAttribute('open', '');
			}
		}
	},
	init: function() {
		this.tipsList.addEventListener('click', e => this.tipClick(e));
		this.tipOpenerDelay();
	},
	tipOpenerDelay: function() {
		setTimeout(() => {
			document.querySelector('#internal_content.tips .tips_container h3').click();
		}, 500);
	}
};

export const initTipsList = () => tipsList.init();
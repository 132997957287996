const headerNav = {
	button: document.querySelector('#toggle_mobile_nav'),
	nav: document.querySelector('#nav'),
	doubleClickLinks: document.querySelectorAll('nav a[d_click]'),
	toggle: function() {
		this.button.switchAttribute('state', 'open', 'closed');
		this.nav.switchAttribute('state', 'open', 'closed');
	},
	init: function() {
		this.button.addEventListener('click', () => this.toggle());
		this.doubleClickLinks.forEach(i => {
			i.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if(!i.hasAttribute('clicked') && (window.innerWidth > 750 || i.hasAttribute('mobile_d_click'))) {
						e.preventDefault();
						i.setAttribute('clicked', true);
					}
				}
			});
		});
	}
};

export const initMobileNav = () => headerNav.init();
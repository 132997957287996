/* global google */

import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper.js';

const facilityTop = {
	caro: document.querySelector('#facility_caro'),
	theMap: {
		ele: document.querySelector('#facility_map'),
		instance: null
	},
	mapBtn: document.querySelector('#map_toggle'),
	mobileToggle: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_top'),
	mobileClick: function() {
		this.mobileToggle.switchAttribute('state', 'closed', 'open');
		this.info.switchAttribute('state', 'closed', 'open');
	},
	buttonClick: function() {
		this.mapBtn.toggleAttribute('active');
		this.theMap.ele.toggleAttribute('show');
	},
	init: async function() {
		
		this.mobileToggle.addEventListener('click', () => this.mobileClick());

		const options = { 
			element: this.caro,
			images: window.facCaroImgs,
			imagesAsBackgrounds: true,
			useChevrons: false,
			showDots: true,
		};

		if (window.facCaroImgs.length === 1){
			options.intervalTiming = false;
			options.showDots = false;
		}

		window.facCaro = AutomatitCarousel(options);

		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '#facility_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span class="map_pin"><img src="/dist/images/icons/map_pin.svg" alt="" /></span>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			this.theMap.instance.setZoom(14);
			this.mapBtn.addEventListener('click', () => this.buttonClick());
		});
	}
};

const facilityFilters = {
	filterContainer: document.querySelector('#facility_filters'),
	filters: document.querySelectorAll('#facility_filters button'),
	rows: document.querySelectorAll('.rates_row'),
	noUnits: document.querySelector('#no_units'),
	filterClick(e) {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) {
			this.filters.forEach(filter => filter.removeAttribute('active'));
			clickedFilter.setAttribute('active', '');

			const activeFilter = document.querySelector('#facility_filters button[active]');

			let numUnits = 0;

			this.rows.forEach(row => {
				row.setAttribute('hide', '');

				if(activeFilter.getAttribute('size') === row.getAttribute('size') || activeFilter.getAttribute('size') === 'all') {
					row.removeAttribute('hide');
					numUnits++;
				}
			});

			if(numUnits) {
				this.noUnits.style.display = 'none';
			} else {
				this.noUnits.style.display = 'block';
			}
		}
	},
	init() {
		this.filterContainer.addEventListener('click', e => this.filterClick(e));
	}
};

export const initFacilityTop = () => facilityTop.init();
export const initFacilityFilters = () => facilityFilters.init();
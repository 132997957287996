import './polyfills.js';

import { initMobileNav } from './header.js';
import { initHeroCaro, initReviewsCaro } from './home.js';
import { initFacilityTop, initFacilityFilters } from './facility.js';
import { contactSubmit } from './contact.js';
import { initmultiMap } from './multiMap.js';
import { initTipsList } from './tips.js';

if(document.querySelector('.tips_list')) {
	initTipsList();
}

initMobileNav();

if(document.querySelector('#home_hero_caro')) {
	initHeroCaro();
}

if(document.querySelector('#home_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#facility_top')) {
	initFacilityTop();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if (document.querySelector('#inc_fac_map')) {
	initmultiMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

import { AutomatitCarousel } from './automatit_carousel.m';

const heroCaro = {
	caro: document.querySelector('#home_hero_caro'),
	init: function() {

		const options = {
			element: this.caro,
			images: [
				{src: "/dist/images/backgrounds/home_hero.jpg", alt: ""},
			],
			imagesAsBackgrounds: true,
			showDots: true,
			useChevrons: false,
		};

		if(options.images.length === 1) {
			options.intervalTiming = false;
			options.showDots = false;
		}

		AutomatitCarousel(options);
	}
};

const reviewsCaro = {
	caro: document.querySelector('#home_reviews_caro'),
	init: function() {
		window.reviewsCaro = AutomatitCarousel({
			element: this.caro,
			useChevrons: false,
		});
	}
};

export const initHeroCaro = () => heroCaro.init();
export const initReviewsCaro = () => reviewsCaro.init();